
/* Fonts */
.thin { font-family: 'Lato', sans-serif; font-weight: 300; }

input,
body { font-family: 'Lato', sans-serif; font-weight: 400; }
strong { font-family: 'Lato', sans-serif; font-weight: 700; }

/* Global & Helpers */
body { color: #000; font-size: 16px; line-height:27px; background: #fff; margin: 0; }

h1 { font-size: 58px; font-weight: 300; display: block; }
h2 { font-size: 47px; font-weight: 300; display: block; }
h3 { font-size: 34px; font-weight: 300; display: block; }
h4 { font-size: 28px; font-weight: 300; display: block; }
h5 { font-size: 24px; font-weight: 300; display: block; }
h6 { font-size: 20px; font-weight: 400; display: block; }
.h7 { font-size: 17px; font-weight: 700; display: block; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 { margin-top: 20px; margin-bottom: 20px; }
h5, .h5, h6, .h6, .h7 { margin-top: 20px; margin-bottom: 10px; }

h1 { margin-bottom: 40px; }

p { font-size: 18px; line-height: 27px; color: #1D1D1D; font-weight: 300; }
small { font-weight: 300; font-size: 15px; line-height: 26px; }

a { color: #00AEEF; text-decoration: none; }
a:hover, a:active, a:focus { color: #00AEEF; text-decoration: none; outline: none; }
::selection { background-color: #00AEEF; color: #fff; }

hr { width: 100%; border-top: 1px solid #E9E9E9; display: inline-block; }

.label-primary { background-color: #00AEEF; }

.container { width:100%; }

.blog { padding-bottom: 30px; }
.blog p { font-size: 17px !important; }

.blogindex { color: #ffffff; font-size: 22px; }
.blogindex:hover { color: #ffffff !important; }

.post-container { overflow: auto; margin-top: 40px; }
.post-thumb { float: left; margin-top: 0px; }
.post-thumb img { display: block }
.post-content { margin-left: 130px }

.author-img { float:left; margin-right: 20px; }
.author-about { padding-top: 20px; }

.pager li > a, .pager li > span { margin-top: 10px; }

.light { color: #fff; background: #333; }
.dark { color: #000; background: #fff; }
.highlight { color: #00AEEF; }
.center {text-align:center;}
.resp-center {text-align:center;}

.watchvideolink { color: #00AEEF; cursor: pointer; }

.m-t-10 { margin-top: 10px; }
.m-t-20 { margin-top: 20px; }
.m-t-30 { margin-top: 30px; }
.m-b-30 { margin-bottom: 30px; }
.p-t-10 { padding-top: 10px !important; }
.m-t-100 { margin-top: 100px; }

.no-padding { padding-left: 0px !important; padding-right: 0px !important; }

.btn { border: 1px solid #fff; font-size: 13px; border-radius: 3px; background: transparent; text-transform: uppercase; font-weight: 700; padding: 15px 40px; min-width: 162px; max-width: 100%; }
.btn:Hover, .btn.hovered { border: 1px solid #fff; }
.btn:Active, .btn.pressed { opacity: 1; border: 1px solid #fff; border-top: 3px solid #17ade0; -webkit-box-shadow: 0 0 0 transparent; box-shadow: 0 0 0 transparent; }
.dropdown-toggle:Active, .dropdown-toggle.pressed { border-top: 0; }
.btn:focus, .btn:active:focus, .btn.active:focus { outline: 0; outline-offset: 0px; }

.btn-default { background: transparent; color: #fff; border: 1px solid #fff; }
.btn-default:Hover, .btn-default.hovered { background: #fff; color: #00AEEF; border: 1px solid #fff; }
.btn-default:Active, .btn-default.pressed { background: #fff; color: #00AEEF; border: 1px solid #fff;}

.btn-primary { background: #00AEEF; border:1px solid #00AEEF; color: #fff; }
.btn-primary:hover, .btn-primary.hovered { background: #00AEEF;  border:1px solid #00AEEF; opacity:0.9; }
.btn-primary:Active, .btn-primary.pressed { background: #00AEEF; border:1px solid #00AEEF; }
.btn-primary.active { background: #00AEEF; border:1px solid #00AEEF; }

.btn-group .btn-default { background: transparent; color: #bbb; border: 1px solid #bbb; }

.btn-secondary { background: transparent; border:1px solid #00AEEF; color: #00AEEF; }
.btn-secondary:hover, .btn-secondary.hovered {background: #00AEEF; border:1px solid #00AEEF; color: #fff; }
.btn-secondary:Active, .btn-secondary.pressed {background: #00AEEF; border:1px solid #00AEEF; color: #fff;}

.btn-lg { font-size: 18px; padding: 22px 40px; }

.img-responsive-full { max-width: 100%; width: 100%; }
.img-circle { -webkit-border-radius: 50%;-moz-border-radius: 50%;border-radius: 50%; overflow:hidden;  }

.section { min-height: 50px; padding-top: 30px; padding-bottom: 30px; position: relative; text-align: center; }
.wrapper { width: 70%; margin: 0 auto; }
.container { z-index: 1; position: relative; }
.icon-active { color: #00AEEF; }
.gap { padding-bottom: 50px; }
hr.no-margin { margin-top:0px; }
article { text-align: left; }

.articles { background: #00AEEF; border-radius: 5px; box-shadow: 0 2px 0 rgba(36,46,53,.06); padding: 0 20px 10px; margin-bottom: 30px; }
.articles .featured { border-radius: 5px 5px 0 0; margin: 0 -20px 10px -20px; }
.articles .featured img { width: 100%; border-radius: 5px 5px 0 0; }
.articles:hover { transform: scale(1.01); }

@media screen and (min-width: 768px) {
    .equal, .equal > div[class*='col-'] {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        flex: 1 0 auto;
    }
}

.sticky { position: fixed; top:0; display: block; width: 100%; z-index: 9; }

.navigation { background: #fafafa; z-index: 2; }
.navigation-brand { float: left; }
.navigation-brand:after { content: ''; display: table; }
.navigation-brand .brand-logo  { width:215px; }
.navigation-brand .brand-logo .logo { display:block; height: 60px; background:url(../img/logo.png) center center no-repeat; }
.fixmenu-clone .brand-logo .logo { background:url(../img/logo.png) center center no-repeat; }
.navigation-brand .brand-logo h4 { margin-top:15px; }
.navigation-brand .brand-logo  a { color:#fff; }
.navigation-navbar { display: block; }
.navigation-bar { margin: 0 auto; padding: 0; list-style: none; }
.navigation-bar-left { float: left; }
.navigation-bar-right { float: right; }
.navigation-bar > li { display: inline-block; }
.navigation-bar > li a { display: inline-block; padding:15px; }
.navigation-bar > li a:Hover,
.navigation-bar > li.active a { color: #fe4918; text-decoration: none; }

.navigation-toggle { position: relative; float: right; padding: 9px 10px; margin: 10px 15px; border-radius: 4px; border: 1px solid #00AEEF; outline: none; background: transparent; }
.navigation-toggle:after { content: ''; clear: both; height: 1%; }
.navigation-toggle .icon-bar { display: block; width: 22px; height: 2px; border-radius: 1px; background: #00AEEF; opacity: 0.7; }
.navigation-toggle .icon-bar+.icon-bar { margin-top: 4px; }

.form .form-group { margin-bottom: 25px; }
.form .form-group:after { content: ''; display: block; clear: both; }
.form .form-group+.form-group { margin-top: -10px; }
.form .form-control { height: 50px; border: 1px solid #d1d8dd; border-radius: 0; box-shadow: 0px 0px 0px transparent; background: #fff; font-size: 17px; font-weight: 300; }
.form .form-group label { text-align: right; line-height: 50px; margin-bottom: 0; }

.form-register { background: #eff3f6; padding: 20px 20px; margin-top: 50px; margin-bottom: 50px; }
.form-register:after { content: ''; display: block; clear: both; }
.form-register .form-control { border-left: 3px solid #1ac6ff; }
.form-register .form-group { margin-bottom: 45px; margin-top: 20px; }
.form-register .form-group .checkbox { margin-bottom: -10px; margin-top: 0px; }
.form-register .form-group .checkbox label { line-height: 1.42857143; }
.agree-text { font-size: 13px; line-height: 21px; padding-top: 15px; text-align: center; margin-bottom: 100px; }
label.error { color: #FF4040; line-height: 0px !important;margin-top: 10px; text-align: center; font-size: 10pt; }
.form-register-small .form-group { margin-bottom: 5px; margin-top: 5px; }

.form-register-small {margin:50px 0px 0px 0px; padding:15px; background: rgba(0,0,0,0.3); border-radius: 3px; display: inline-block; }
.form-register-small .form-control {height:45px; border-radius:0; border-left: 3px solid #1ac6ff;}
.form-register-small .form-group { margin-right:10px; }

.register-version#hero .btn-primary, .register-version .submit-wrap { width:auto; }
.register-version .signup-wrapper { text-align:center; width:auto; }


/* Elements */
.sub-title { font-size: 18px; font-weight: 300; color: #8D8D8D; padding: 0px 0px 8px 0px; }
.close { font-weight: 300; font-size: 28px; color: inherit !important; opacity: .8; }


.dropdown { font-size: 13px; line-height: 28px; text-align: left; }
.dropdown .btn { padding: 14px 14px; border-top: 2px solid #00AEEF;  }
.dropdown .caret { margin-left: 7px; border-top: 0; border-bottom: 0; border-left: 0; border-right: 0; position: relative; top: -11px; }
.dropdown .caret .fa { color: #00AEEF; font-size: 16px; }
.dropdown-menu { background: #dadfe3; border:none; border-bottom: 2px solid #00AEEF; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; padding-top: 10px; padding-bottom: 10px; -webkit-border-radius: 0 0 0 transparent; border-radius: 0 0 0 transparent; box-shadow: 0px 0px 0px transparent; }
.dropdown-menu > li > a { font-size: 15px; padding: 14px 20px; }
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus { background: #00AEEF; color: #fff; }

.long-block { background: #00AEEF; text-align: left; padding-top: 5px; padding-bottom: 15px; }
.long-block h2 { margin-bottom: 0; letter-spacing: -1px; }
.long-block .icon { font-size: 60px; position: relative; margin-right: 15px; margin-top: 20px; }
.long-block .btn-default { margin-top: 35px; }



.nav-tabs > li.active a { -webkit-box-shadow: inset 0px 8px 0px -5px #00AEEF;-moz-box-shadow: inset 0px 8px 0px -5px #00AEEF; box-shadow: inset 0px 8px 0px -5px #00AEEF; }
.nav-tabs > li > a { font-size: 13px; text-transform: uppercase; font-weight: 700; color: #30353A; margin-right: 0px; background-color: #eff3f6; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; padding: 20px 60px; border-left: 0; border-right: 0; }
.nav-tabs > li > a:Hover { background: #00AEEF; color: #fff; }
.tab-content .tab-pane { padding: 16px 30px; border: 1px solid #ddd; border-top: 0; text-align: left; }


blockquote { position: relative; display: block; padding: 0 50px; text-align: center; font-size: 20px; line-height: 33px; border: 0; font-weight: 300; }

blockquote:before { position: absolute; content: " "; background: url(../img/sprites.png) no-repeat top left; background-position: -593px 0; width: 36px; height: 22px; left: 0; top:25%; }
blockquote:after { position: absolute; content: " "; background: url(../img/sprites.png) no-repeat top left; background-position: -593px -25px; width: 36px; height: 22px; right: 0; top:25%; }


/* Alternative tab slider */
.alt.nav-tabs { margin-bottom: 50px; border:0;  display: inline-block; }
.alt.nav-tabs  { border-left: 1px solid #ddd; border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; border-radius: 3px; }
.alt.nav-tabs  li { border-right: 1px solid #ddd; margin-bottom:0; }
.alt.nav-tabs > li.active { border-top: 0; }
.alt.nav-tabs > li.active > a,
.alt.nav-tabs > li.active > a:hover,
.alt.nav-tabs > li.active > a:focus { font-weight: 400; color: #fff; background:#00AEEF;}
.alt.nav-tabs > li.active > a:hover {border:0;}
.alt.nav-tabs > li > a { font-size: 13px; text-transform: uppercase; font-weight: 400; color: #596068; margin-right: 0px; background-color: #fff; border:0; -webkit-border-radius: 0; -moz-border-radius: 0; border-radius: 0; padding: 20px 60px;}

.alt.nav-tabs > li > a:Hover { background: #00AEEF; color: #fff;}
.alt.tab-content .tab-pane { padding: 0; border: 0; text-align: left; }
.alt.tab-content .tab-pane .btn { margin-top:15px; }

/* Navigation Header */
.navigation-header { position: absolute; top:10px; background: transparent; left: 0; right: 0; width: 100%; z-index: 99; }
.navigation-header .navigation-brand { margin-right: 100px; margin-top: 3px; }
.navigation-header .navigation-bar > li { padding: 15px 15px; }
.navigation-header .navigation-bar > li > a { font-size: 15px; text-transform: uppercase; color: #eff3f6; border-bottom: 2px solid transparent; padding: 10px 0; line-height: 13px; opacity: 0.8; }

.navigation-header .navigation-bar > li > a:Hover { opacity: 0.9; }
.navigation-header .navigation-bar > li.active > a { color: #fff; border-bottom: 2px solid #00AEEF; opacity: 0.9; }
.navigation-header .navigation-bar > li.featured { padding: 15px 0; }
.navigation-header .navigation-bar > li.featured > a { border: 1px solid #00AEEF; color: #00AEEF; padding: 10px 25px; display: inline-block; opacity: 1; border-radius: 3px; }
.navigation-header .navigation-bar > li.featured > a:Hover { opacity: .9; background:#00AEEF; color:#fff; }


/* Hero section */
#hero .btn-primary:hover, #hero .btn-primary.hovered { background: #8DD8F8;  border:1px solid #00AEEF;}
#hero .list-inline { margin-top: 50px; }
.static-header { background: url('../img/splash-bg.jpg') no-repeat center center; -webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover; background-attachment: fixed; min-height: 1155px; position: relative; }
.static-header-short { background: url('../img/splash-bg.jpg') no-repeat center center; -webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover; background-attachment: fixed; min-height: 100px; position: relative; }
.text-heading { position: relative; width: 100%; left: 0; right: 0; text-align: center; padding-top: 135px; }
.text-heading p { font-size: 23px; font-weight: 300; }
.static-header h4 { text-align: center; display: block; position: absolute; bottom: 20px; left: 0; right: 0; width: 100%; margin-bottom: 0; }

/* Header versions */
.plain-version .text-heading { padding-top: 15%; }


#landing-page .static-header { min-height: 455px; }

.static-header .text-heading { margin-bottom: -10px; z-index: 20; padding-left: 10px; padding-right: 10px; }
.static-header .text-heading p { color:#fff; }
.static-header .list-inline { padding: 30px 0; }
.static-header .list-inline li { margin-left: 15px; margin-right: 15px; }
.embed-container { position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden; max-width: 100%; height: auto; z-index: 9; }
.embed-container .EDGE,
.embed-container iframe,
.embed-container object,
.embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.embed-container iframe { border: 0; }

.section .section-header { margin-top: 30px; margin-bottom: 60px; }

.light .sub-heading, .light p { color: #fff; }

.inverted h2 { color: #00AEEF; }
.inverted blockquote { color: #fff; opacity: 0.8; }
.inverted .sub-heading { color: #fff; opacity:0.8}
.inverted .fancy-line span:before, .inverted .fancy-line span:after { opacity: 0.3; }
.inverted .fancy-line span {background:#fff; opacity:0.8;}

.sub-heading { font-size: 22px; line-height: 28px; color: #6f6f6f; font-weight: 300; }
.fancy-line { line-height: 0.5; text-align: center; margin-bottom: 30px; }
.fancy-line span { display: inline-block; position: relative; background: #00AEEF; width: 5px; height: 5px; -webkit-border-radius: 50px; border-radius: 50px; }
.fancy-line span:before,
.fancy-line span:after { content: ''; position: absolute; height: 5px; border-bottom: 2px solid #e4e4e4; top: -2px; width: 110px; }
.fancy-line span:before { right: 100%; margin-right: 15px; }
.fancy-line span:after { left: 100%; margin-left: 15px; }

#how  { padding:30px 0px 60px 0px;}
#features { background: #f0f0f0; padding: 0px 0px 30px 0px; margin-bottom: 140px; }

.howitworks { font-size:81px; vertical-align: middle; }

#testimonials {  background: url('../img/splash-bg.jpg') no-repeat center center; -webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover; background-attachment: fixed; overflow: hidden; padding: 20px 0px 0px 0px; }
#testimonials.section .section-header { margin-bottom: 30px; }
#testimonials-controls {  background: #fff;padding: 10px 0px 0px 0px; }

/* Testimonials slider */
.flexslider { margin-bottom:30px; }
.flexslider ul, ol { list-style:none; padding: 0; }
.flex-manual .wrap { padding: 0px; }
.flex-manual .switch { padding: 15px 0px; margin: 0px 0px 0px 30px; }
.flex-manual .switch { opacity:0.75; height:75px; text-align:left; }
.flex-active > .img-circle{ border-color:#fff; }
.flex-manual .switch.flex-active, .flex-manual .switch:hover { opacity:1; cursor: pointer; cursor: hand; }
.flex-manual .switch.flex-active .highlight, .flex-manual .switch:hover .highlight { opacity:1; cursor: pointer; cursor: hand;  color:#00AEEF; }
.flex-manual .switch.flex-active  p, .flex-manual .switch:hover p { opacity:1; cursor: pointer; cursor: hand;  color:#525252; }

.flex-manual, .flex-manual  .switch { height:120px; }
.flex-manual  { margin-top: 15px; border-bottom:1px solid #E9E9E9; }

.switch.flex-active { border-bottom:1px solid #00AEEF; }
.switch p, .switch .highlight { color:#dbdbdb; }
.switch p { padding: 12px 0px 0px 80px; font-size:12pt; }
.switch img { line-height:70px; vertical-align:middle; }
.flex-manual .switch.flex-active .name { color: #00AEEF; }


.package-column , .package-details { -webkit-transition: all 300ms ease-in-out; -moz-transition: all 300ms ease-in-out; transition: all 300ms ease-in-out;}
.package-column { border: 1px solid #F0F0F0; background: #F7F7F7;}

.package-column:hover, .package-column.current { background: #00AEEF; }
.package-column:hover > .package-price, .package-column.current > .package-price  { background:rgba(71, 72, 76, 0.2); }
.package-column:hover > .package-detail ul li, .package-column.current > .package-detail ul li { border-color: rgba(255,255,255,0.3); }
.package-column:hover > .package-price .price, .package-column.current > .package-price .price,
.package-column:hover > .package-price .period, .package-column.current > .package-price .period,
.package-column:hover > .package-title, .package-column.current > .package-title,
.package-column:hover > .package-title .highlight, .package-column.current > .package-title .highlight,
.package-column:hover > .package-detail ul li, .package-column.current > .package-detail ul li { color: #fff; }

.package-column.featured > .package-detail .btn.btn-secondary, .package-column:hover > .package-detail .btn.btn-secondary  { color: #fff ; border-color:#fff !important; }

.package-detail .btn.btn-secondary:hover { color: #00AEEF !important; background: #fff !important; border-color:#fff !important; }
.package-column.current .btn-secondary {border-color:#fff !important; color:#fff;}


.package-column .package-title { color: #47484c; font-size: 20px; padding: 20px 0 18px; font-weight: 300; text-transform: uppercase; }
.package-column .package-price { background:rgba(171, 172, 179, 0.1); padding: 15px; min-height:211px;}
.package-column .package-price .price { color: #00AEEF; font-size: 110px; font-weight: 300; line-height: 60px; position: relative; margin-top: 20px; }
.package-column .package-price .currency { font-size: 47px; line-height: 47px; font-weight: 300; position: relative; top:-40px; }
.package-column .package-price .period { font-size: 15px; color: #00AEEF; text-transform: uppercase; }
.package-column .package-detail { padding: 30px 15% 50px; }
.package-column .package-detail ul { margin-bottom: 30px; }
.package-column .package-detail ul li { font-weight: 300; font-size: 16px; color: #47484c; border-bottom: 1px solid #dadfe3; padding: 15px 0; }
.package-column .package-detail ul li:last-child { border-bottom: none; }


#featuredTab { position: relative; margin-top: 160px; }
#featuredTab li { display: block; position: relative; }
#featuredTab li > a { display: block; }
#featuredTab li > a:after { content: ''; clear: both; display: block; }
#featuredTab li > a .tab-info { float: left; text-align: left; display: block; width: 346px; padding: 25px 28px; position: relative; z-index: 2; }
#featuredTab li > a .tab-icon { float: right; text-align: right; display: block; width: 113px; position: relative; z-index: 2; }
#featuredTab li > a .tab-info .tab-title { font-size: 17px; line-height: 21px; font-weight: 700; color: #00AEEF; text-transform: uppercase; padding-bottom: 10px; }
#featuredTab li > a .tab-info .tab-desc { font-size: 15px; line-height: 28px; color: #5f5f5f; font-weight: 300; display: block; }
#featuredTab li > a .icon { font-size: 50px; padding: 21px 7px; display: block; padding: 46px 28px; }
#featuredTab li.active { margin-top: -1px; padding-bottom: 2px; }
#featuredTab li.active > a .tab-info { -webkit-border-top-left-radius: 5px; -moz-border-radius-topleft: 5px; border-top-left-radius: 5px; -webkit-border-bottom-left-radius: 5px; -moz-border-radius-bottomleft: 5px; border-bottom-left-radius: 5px; background: #00AEEF; }
#featuredTab li.active > a .tab-icon { -webkit-border-top-right-radius: 5px; -moz-border-radius-topright: 5px; border-top-right-radius: 5px; -webkit-border-bottom-right-radius: 5px; -moz-border-radius-bottomright: 5px; border-bottom-right-radius: 5px; background: #00AEEF; }
#featuredTab li.active > a .tab-info .tab-title,
#featuredTab li.active > a .tab-info .tab-desc,
#featuredTab li.active > a .icon { color: #fff; }
#featuredTab li a .tab-info:after,
#featuredTab li a .tab-icon:after { content: ''; width: 99%; border-bottom: 1px solid #dadfe3; display: block; position: absolute; bottom: 0; right: 0; }
#featuredTab li a .tab-icon:after { left: 0; right: auto; }
#featuredTab li:last-child a .tab-info:after,
#featuredTab li:last-child a .tab-icon:after,
#featuredTab li.active a .tab-info:after,
#featuredTab li.active a .tab-icon:after { display: none; }
#featuredTab .tab-content .tab-pane { border: none; padding: 0; }


#subscribe input { margin-right: 8px; }
#subscribe .form-control { border-left: 3px solid #1ac6ff; }
#response { display: block; text-align: center; position: absolute; margin-left: 7%; }



/* Footer */
.footer { background: url('../img/splash-bg.jpg') no-repeat top center; -webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover; background-attachment: fixed; color: #ababab; font-weight: 300; padding: 0px 0px 30px 0px; }
.footer p, .footer li { color: #fff; }
.footer p { font-size: 15px; line-height: 24px; }
.footer a { color: #ffffff; }
.footer a:hover { text-decoration: underline; }
.footer .logo-wrapper { margin-bottom: 20px; }
.footer .footer-content { padding: 75px 0 65px; }
.footer .footer-title { color: #fff; font-size: 17px; text-transform: uppercase; font-weight: 700; text-align: center; margin-bottom: 25px; }
.footer .list-inline { text-align: center; margin: 0 auto; }
.footer .list-inline li { padding-left: 15px; padding-right: 15px; padding-bottom: 15px; }
.footer .list-inline li .icon { color: #00AEEF; font-size: 50px; }
.footer .list-unstyled li { position: relative; padding-left: 0px; margin-bottom: 15px; font-size: 15px; line-height: 24px; }
.footer .list-unstyled li .icon { position: absolute; left: 0; color: #00AEEF; font-size: 20px; }
.copyright { text-align: center; color: #fff; font-size: 14px; padding: 10px; font-weight: 300; opacity: 0.4; }
.footer .socials a:hover { text-decoration: none; opacity: 0.7; }

.video-width { width: 60% }

.rev-big { font-size: 58px; font-weight: 300; color: #fff; }
.rev-normal { font-size: 23px; line-height: 27px; font-weight: 300; color: #fff; }
.rev-big span,
.rev-normal span { color: #00AEEF; }
.tp-caption a { color: #fff; }
.tp-caption a:Hover { color: #fafafa; }
.tp-caption .list-inline li { margin-left: 15px; margin-right: 15px; }

.fixmenu-clone {
    position: fixed;
    top: 0;
    left: 0;
    background: url('../img/splash-bg.jpg') no-repeat center center;
    -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transition: all 300ms ease-in-out;
       -moz-transition: all 300ms ease-in-out;
            transition: all 300ms ease-in-out;
    -webkit-box-shadow: 0px 1px 5px 0px rgba(100,100,100,.3);
       -moz-box-shadow: 0px 1px 5px 0px rgba(100,100,100,.3);
            box-shadow: 0px 1px 5px 0px rgba(100,100,100,.3);
}
.fixmenu-stick {
    -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
            transform: translateY(0%);
}
.fixmenu-unstick { }

.logo-inverted,
.fixmenu-clone .logo { display: none; }
.fixmenu-clone .logo-inverted { display: block; }
.fixmenu-clone.navigation-header .navigation-bar > .featured > a { color: #00AEEF; }
.fixmenu-clone.navigation-header .navigation-bar > .featured > a:hover { color: #fff; }
.fixmenu-clone.navigation-header .open .navigation-bar > li > a { color: #fff; }
.fixmenu-clone.navigation-header .open .navigation-bar > .featured > a { color: #00AEEF; }
.fixmenu-clone.navigation-header .navigation-bar > li > a:Hover,
.fixmenu-clone.navigation-header .navigation-bar > li.active > a { color: #00AEEF; }
.fixmenu-clone.navigation-header .navigation-brand { padding-left: 15px; padding-right: 15px; display: block; margin-right: 15px; margin-left: -15px; }
.fixmenu-clone.navigation-header .navigation-toggle { border: 1px solid #00AEEF; opacity: 1;}
.fixmenu-clone.navigation-header .navigation-toggle .icon-bar { background: #00AEEF; }


/* Mobile device 767px */
@media (max-width: 767px) {
	p { font-size: 14px; line-height: 25px; }
	.static-header, #testimonials, .footer { background-size: auto !important; background-size: auto !important; background-position: bottom left; background-attachment: scroll !important;}

	.nav-tabs .btn { margin:0px 10px 25px 0px; }

    .video-width { width: 90% }

    .m-t-100 { margin-top: 10px; }

    .wrapper { width: 100%; }

    .post-thumb { margin-top: 15px; }

    .author-about { padding-top: 0px; }

    .register-version .signup-wrapper { max-width:750px; margin:0 auto; padding-left: 15px; padding-right: 15px; }
    .register-version#hero .btn-primary { width:100%; }
    .form-register-small { display: block; }
    .form-register .form-group label.error  { text-align:right; }

	.flex-manual, .flex-manual .switch  { height:150px; text-align: center; }

    .pull-left { float:none !important; }

	.switch p { padding: 10px 0px; }

    #sc-button ul.list-inline > li { display: block; margin-bottom: 8px; }
    .social-icons li { width: 15%; }

    #sc-dropsocial .row > :first-child { padding-bottom: 40px; text-align: center; }
    .dropdown .btn { width: 100%; }
    .dropdown-menu { position: relative; float: none; left: auto; }
    .social-icons { margin-top: 0; }

    .long-block { text-align: center; }
    .long-block .icon { top:0; margin-bottom: 20px; margin-right: 0; display: block; }
    .long-block .pull-left { float: none !important; }
    .long-block .btn.btn-default { margin-left: auto; margin-right: auto; }

    blockquote:before { top: 10%; }
    blockquote:after { top: 90%; }

    h1 { margin-bottom: 40px; }

    .text-heading { padding-top: 110px; }

    .form-register { margin-top: 50px; }
    .form-register .form-group { margin-bottom: 10px; margin-top: 0; margin-left: -15px; margin-right: -15px;}
    .form-register .form-group label { line-height: 30px; margin-top: 15px; text-align: left; }
    .form-register-small label.error { display: inline-block; position:relative; }
    .form-register .form-group .checkbox { margin-bottom: 20px; margin-top: 30px; }
    .form-register-small .form-group { margin-left:0; margin-right:0; }

    .register-version .data-wrap { width:100% !important; }

    article { text-align: center; }


    .text-heading h1 { font-size: 44px; }
    .text-heading p { font-size: 18px; line-height: 24px; }
    .text-heading .btn { margin-bottom: 30px; }

    .long-block h2 { font-size: 36px; }


	.logos li { display:inline; padding:10px !important; }

    #features .pull-right { float: none !important; }
    #features article { padding-top: 0; }
    #features .col-sm-6 { padding-bottom: 0; }

    #featuredTab { margin-top: 0; }
    #featuredTab li { display: inline-block; width: 32%; border-bottom: 1px solid #d0d0d0; margin-bottom: 20px; }
    #featuredTab li.active { margin-top: 0; padding-bottom: 0; }
    #featuredTab li > a:after,
    #featuredTab li a .tab-info:after,
    #featuredTab li a .tab-icon:after
    #featuredTab li.active > a:after { display: none; }
    #featuredTab li > a .tab-info { width: 100%; padding: 15px; line-heigh: 18px; }
    #featuredTab li > a .tab-info .tab-desc { line-height: 22px; }
    #featuredTab li.active a .tab-info { -webkit-border-bottom-left-radius: 0; -moz-border-radius-bottomleft: 0; border-bottom-left-radius: 0; -webkit-border-top-right-radius: 5px; -moz-border-radius-topright: 5px; border-top-right-radius: 5px; }
    #featuredTab li > a .tab-icon { display: none; }
    #featuredTab .tab-content { padding: 0; border: none; z-index: 1; }
    #featuredTab .tab-content .tab-pane { text-align: center; }

	.package-column { margin-bottom: 30px; }

    .footer-content > div { margin-bottom: 30px; }
    .footer-content > div:last-child { margin-bottom: 0; }
    .footer .list-inline li .icon { font-size: 30px; }

    #guarantee h2, #guarantee p { text-align: center; }
}

/* Mobile device 540px */
@media (max-width: 540px) {
	.revo #main-slider .list-inline { display: none !important; }
	.static-header, #testimonials, .footer { background-size: auto !important; background-size: auto !important; background-position: top center; background-attachment: scroll !important;}
}

@media (max-width: 480px) {
	.section { padding: 35px 0; }
	.revo #main-slider .rev-big, .revo #main-slider .rev-normal { display: none; }
	.static-header, #testimonials, .footer { background-size: auto !important; background-size: auto !important; background-position: top center; background-attachment: scroll !important;}

	.text-heading { padding-top: 85px; }
	.text-heading h1 { font-size: 35px; }
    .text-heading h3 { font-size: 25px; }
	.text-heading .btn { margin-bottom: 10px; }

	.plain-version .text-heading { padding-top: 30%; }

	#how { padding: 40px 0px 0px 0px; }
	.alt.nav-tabs > li > a { padding: 15px 12px; }
	.alt.tab-content .tab-pane { padding-bottom: 15px; }
	.alt.tab-content .tab-pane .btn { margin-top: 0px; }
	.tab-content .btn { margin:0px 0px 15px 0px; }

	.logos li { display:inline; padding:10px !important; }

	.section-content article { text-align: center; }

	#featuredTab li.active > a .tab-info { -webkit-border-radius: 0px; -moz-border-radius: 0; border-radius: 0; }
	#featuredTab li > a .tab-info .tab-title { font-size: 15px; }
	#featuredTab li > a .tab-info .tab-desc { font-size:14px;  }

	.flex-manual .switch { margin: 0px 0px 0px 0px; }
	blockquote { padding:  0px 50px;  font-size: 14px; line-height: 25px;}
    #subscribe input { margin-right: 0px; }

    .form .form-group { margin-bottom: 15px; }
	.long-block { padding-top: 5px; padding-bottom: 10px; }
	.long-block .btn-default, .long-block input { margin-top: 10px; }
	#response { margin-left: 22%; margin-top:5px;  font-size: 80%; }
	#guarantee p { margin-top: 7px; }
	.social-wrap{ display:block; height: 140px; }
	.social-wrap > ul { float:left; }
	.social-wrap:after { clear:both; }

	.flex-manual { border-bottom: 0px solid #E9E9E9; }
	.flex-manual, .flex-manual .switch { height: 100px; }

    .form-register-small .submit-wrap { text-align:center; margin-top:15px;}
    .form-register-small { padding: 15px 10px; display: block; margin-top: 30px; }
    .form-register-small .form-group { margin-left:0; margin-right:0; }

	.footer .footer-content { padding: 35px 0 15px; }

}

/* Mobile device 340px */
@media (max-width: 340px) {
	.navigation-brand .brand-logo {
		-webkit-transform : scale(0.8);
		-moz-transform    : scale(0.8);
		-ms-transform     : scale(0.8);
		transform         : scale(0.8);
		margin-left: -10px;
	}
	.nav-tabs .btn { margin:0px 10px 25px 0px; }
}

/* Tablet device Landscape 1024px and 768px */
@media (max-width: 1024px) and (min-width: 768px) {
	p { font-size:14px; line-height:25px; }

    .video-width { width: 75% }

    .m-t-100 { margin-top: 10px; }

    .package-column .package-price .currency { top:-20px; }
    #landing-page .plain-version.static-header { min-height: 460px; }
	.text-heading { padding-top: 95px; }

    .wrapper { width: 90%; }
	.static-header, #testimonials, .footer { background-size: auto !important; background-size: auto !important; background-position: top center; background-attachment: scroll !important;}
    .list-inline img { max-width: 90%; }

	#clients ul.list-inline li { padding-left: 15px; padding-right: 15px; }

    .long-block { text-align: center; padding-top: 10px; padding-bottom: 10px;}
	.long-block, .long-block article { text-align: center; }
    .long-block .icon { top:0; margin-bottom: 20px; margin-right: 0; display: block; }
    .long-block .pull-left { float: none !important; }
    .long-block .btn.btn-default { margin-left: auto; margin-right: auto; }

	.alt.tab-content .tab-pane { padding-bottom: 20px; }

    #landing-page .static-header h1 { font-size: 48px; }

    #featuredTab { margin-top: 0; }
    #featuredTab li { display: inline-block; width: 33%; border-bottom: 1px solid #d0d0d0; margin-bottom: 20px; }
    #featuredTab li.active { margin-top: 0; padding-bottom: 0; }
    #featuredTab li > a:after,
    #featuredTab li a .tab-info:after,
    #featuredTab li a .tab-icon:after
    #featuredTab li.active > a:after { display: none; }
    #featuredTab li > a .tab-info { width: 100%; }
	#featuredTab li.active > a .tab-info { -webkit-border-radius: 0px; -moz-border-radius: 0; border-radius: 0; }
    #featuredTab li > a .tab-icon { display: none; }
    #featuredTab .tab-content { padding: 0; border: none; z-index: 1; }
    #featuredTab .tab-content .tab-pane { text-align: center; }

	.package-column .package-price .price { font-size:80px; }
	.switch p { padding: 0px 0px 0px 80px; font-size: 10pt; }

	#response { margin-left: 40%; }

	#guarantee h2, #guarantee p { text-align: center; }

    h3 { font-size: 28px; }

}

/* Tablet device Portrait 1024px and 768px */
@media (max-width: 1024px) and (orientation:portrait) {

	p { font-size:14px; line-height:25px; }
	.btn { padding: 15px 35px; }
	.footer .list-inline li .icon { font-size: 35px; }

}

@media (max-width: 1024px) {

	.static-header, #testimonials, .footer { background-size: auto !important; background-size: auto !important; background-position: top center; background-attachment: scroll !important;}

}

@media (max-width: 991px) {
    .navigation .container { padding:0; }
    .navigation-brand { float: none; margin-right: 0 !important; }
    .navigation-brand .brand-logo { padding: 8px 0px 0px 0px; margin-left:15px; float: left; }
    .navigation-header .navigation-brand { margin:0px 0px 0px 0px; }

    .navigation-bar > li,
    .navigation-bar > li a { display: block; }
    .navigation-navbar { overflow: hidden; height: 0; display: block; background: url('../img/splash-bg.jpg') no-repeat center center; text-align: center; }
    .navigation-navbar.open { height: auto; margin-left: -15px; margin-right: -15px; padding: 10px 0; border-top:3px solid #00AEEF; }
    .navigation-brand:after { content: ''; clear: both; }
    .navigation-bar-left, .navigation-bar-right { float: none; }
    .navigation-header .navigation-bar > li,
    .navigation-header .navigation-bar > li.featured { padding: 5px 15px; }
    .navigation-header .navigation-bar > li.featured > a { padding: 0px 25px; }
    .navigation-header .navigation-bar > li.featured > a:Hover { border-bottom: 1px solid #00AEEF; }
    .navigation-header .navigation-bar > li > a { padding: 0px; z-index:999; display: block; position:relative; height: 30px; line-height: 30px;}
    .navigation-header .navigation-bar > li > a:Hover,
    .navigation-header .navigation-bar > li.active > a { color: #00AEEF; border-bottom: 2px solid transparent; }

    .register-version .signup-wrapper { max-width:750px; margin:0 auto; padding-left: 15px; padding-right: 15px; }
    .register-version .form-register-small { padding: 15px 15px;}
    .register-version#hero .btn-primary, .register-version .submit-wrap { width:100%; }
    .register-version .submit-wrap { width:100%; margin-top: 7px; }
    .register-version .data-wrap { width:48%; }
    .register-version .data-wrap input { width:100%; }
    .register-version .signup-wrapper { text-align:left; }
    .register-version .signup-wrapper #fullname { width: 273px; }
    .register-version .signup-wrapper .password-wrapper { margin-right:0; }
    .register-version .signup-wrapper .password-wrapper .form-control { width: 177px; }

    .long-block .btn-default, .long-block input { margin-top: 10px; }

}

/* Desktop medium screen 992px and 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
	.long-block, .long-block article { text-align: center; }
	.long-block .pull-left { float: none !important; }
	.long-block .icon { top: 0; margin-bottom: 20px; margin-right: 0; display: block; }
	.navigation-header .navigation-brand { margin-right: 50px; }
    .navigation-header .navigation-bar > li > a{ font-size:14px; }
    .navigation-header .navigation-bar > li { padding: 16px 10px; }
	.long-block { padding-top: 10px; padding-bottom: 15px; }
    .list-inline img { max-width: 90%; }

    .author-about { padding-top: 10px; }


    #sc-longblock article { width: 80%; }
    .alt.tab-content .tab-pane { padding-bottom: 25px; }
    .alt.tab-content .tab-pane .btn { margin-top:-15px; margin-right:15px;}

	#clients ul.list-inline li { padding-left: 15px; padding-right: 15px; }

	#response { margin-left: 40%; }

    #featuredTab { margin-top: 0; }
    #featuredTab li { display: inline-block; width: 33%; border-bottom: 1px solid rgba(255, 255, 255, .2); margin-bottom: 20px; opacity:0.7;}
    #featuredTab li.active { margin-top: 0; padding-bottom: 0; opacity:1; }
    #featuredTab li > a:after,
    #featuredTab li a .tab-info:after,
    #featuredTab li a .tab-icon:after
    #featuredTab li.active > a:after { display: none; }
    #featuredTab li > a .tab-info { width: 100%; }
	#featuredTab li.active > a .tab-info { -webkit-border-radius: 0px; -moz-border-radius: 0; border-radius: 0; }
    #featuredTab li > a .tab-icon { display: none; }
    #featuredTab .tab-content { padding: 0; border: none; z-index: 1; }
    #featuredTab .tab-content .tab-pane { text-align: center; }
}


/* Desktop large screen 1200px */
@media (min-width: 1200px) {
    .container { width: 1200px; }

    .navigation-header .navigation-bar > li { padding: 15px 15px; }

    #landing-page .plain-version.static-header { min-height: 700px; }

    #featuredTab { position: relative; margin-top: 115px; }
    #featuredTab li { display: block; position: relative; width: 100%; border-bottom: none; margin-bottom: 0; line-height: 0px; }
    #featuredTab li > a:after { content: ''; clear: both; display: block; }
    #featuredTab li > a .tab-info { float: left; text-align: left; display: block; width: 345px; padding: 25px 28px; position: relative; z-index: 2; }
    #featuredTab li > a .tab-icon { float: right; text-align: right; display: block; width: 113px; position: relative; z-index: 2; }
    #featuredTab li > a .tab-info .tab-title { font-size: 17px; line-height: 21px; color: #00AEEF; text-transform: uppercase; padding-bottom: 10px; font-weight: 600;}
    #featuredTab li > a .tab-info .tab-desc { font-size: 16px; line-height: 22px; color: #5f5f5f; font-weight: 300; display: block;}
    #featuredTab li > a .icon { font-size: 50px; padding: 21px 7px; display: block; padding: 46px 28px; }

    #featuredTab li.active > a .tab-info { -webkit-border-top-left-radius: 5px; -moz-border-radius-topleft: 5px; border-top-left-radius: 5px; -webkit-border-bottom-left-radius: 5px; -moz-border-radius-bottomleft: 5px; border-bottom-left-radius: 5px; -webkit-border-top-right-radius: 0; -moz-border-radius-topright: 0; border-top-right-radius: 0; background: #00AEEF; }
    #featuredTab li.active > a .tab-icon { -webkit-border-top-right-radius: 5px; -moz-border-radius-topright: 5px; border-top-right-radius: 5px; -webkit-border-bottom-right-radius: 5px; -moz-border-radius-bottomright: 5px; border-bottom-right-radius: 5px; background: #00AEEF; }
    #featuredTab li a .tab-info:after,
    #featuredTab li a .tab-icon:after { content: ''; width: 99%; border-bottom: 1px solid rgba(255, 255, 255, .2); display: block; position: absolute; bottom: 0; right: 0; }
    #featuredTab li a .tab-icon:after { left: 0; right: auto; }
    #featuredTab li:last-child a .tab-info:after,
    #featuredTab li:last-child a .tab-icon:after,
    #featuredTab .tab-content { position: absolute; top: -60px; right: 0px; padding: 0; border: none; z-index: 1; }

}

/* New Style Starts */
.data-container .section {padding-top:0 !important;}
.emailText {color:#eff3f6 !important; font-size:13px; padding-right:5px;}
.smlText {font-size: 13px; padding-bottom: 5px;}
#form-register-error, #form-contact-us-error{text-align: left;}
#form-contact-us-success{color: #00dd00;}
.black-btn {background: rgba(0,0,0,0.9); border-radius: 3px; padding:10px; float:right; margin-top: 23px;}
.black-btn .btn-default { background: #00AEEF; border:1px solid #00AEEF; color: #fff; }
.black-btn .btn-default:hover, .black-btn .btn-default.hovered { background: #00AEEF;  border:1px solid #00AEEF; opacity:0.9; }
.black-btn .btn-default:Active, .black-btn .btn-default.pressed { background: #00AEEF; border:1px solid #00AEEF; }
.black-btn .btn-default.active { background: #00AEEF; border:1px solid #00AEEF; }
.black-btn .btn-default {margin-top: 0;}
