.sprite { background: url(../img/sprites.png) no-repeat top left; width: 70px; height: 70px; display: inline-block; }
.sprite.doc { background-position: 0 0; width: 81px; height: 81px; }
.sprite.view { background-position: -91px 0; width: 81px; height: 81px; }
.sprite.send { background-position: -182px 0; width: 81px; height: 81px; }
.sprite.play { background-position: -597px -48px; width: 30px; height: 30px; }
.sprite.casey { background-position: -273px 0; }
.sprite.peter { background-position: -353px 0; }
.sprite.nathan { background-position: -433px 0; }
.sprite.twitter { background-position: -513px 0; height: 57px; }

blockquote:after { position: absolute; content: " "; background: url(../img/sprites.png) no-repeat top left; background-position: -593px -25px; width: 36px; height: 22px; right: 0; top:25%; }